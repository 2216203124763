import React from "react";
import "./benefits.css";
import CardComponent from "../CardComponent/CardComponent";

/**
 * Renders the Benefits component.
 * This component displays the benefits of joining IEEE AlexSB.
 * @returns {JSX.Element} The Benefits component.
 */
const Benefits = () => {
  return (
    <div>
      <div className="section1 title-text">
        <h1><center>What is IEEE?</center></h1>
        <h3><center>A trusted voice in engineering, technology, and research.</center></h3>
        <div className="flex-container">
          <CardComponent
            title="About IEEE"
            description={
              <>
                IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity. Learn more about our mission and the impactful work we do by visiting the <a href="https://www.ieee.org" target="_blank" style={{ textDecoration: 'none', transition: 'text-decoration 0.3s' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>IEEE</a> website.
              </>
            }
            image="https://3.bp.blogspot.com/-MEQbWHgj2GU/XhWkjdyL5uI/AAAAAAAAEc0/sJlhEwETdG8XWhzq1BN8btQWk1bIc2ZuQCLcBGAsYHQ/s1600/ieee-tvlp-icte-2020.jpg"
            width="400"
            height="400"
            position="left"
          />
          <CardComponent
            title="Join IEEE"
            description={
              <>
                Becoming an IEEE member connects you with a global community of professionals, access to exclusive resources, and opportunities for career advancement. Discover the benefits and join today by visiting <a href="https://www.ieee.org/membership/join/index.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', transition: 'text-decoration 0.3s' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>IEEE Membership</a>.
              </>
            }
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-hfoGD4y1B_NDz2Rh80SMUy-4BJif3v9drgDKTmuGHQ&s"
            width="400"
            height="400"
            position="left"
          />
          <CardComponent
            title="IEEE Activities"
            description={
              <>
                From conferences and workshops to publications and standards, IEEE offers a wide range of activities that support technical innovation and professional development. Explore upcoming events and opportunities on the <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', transition: 'text-decoration 0.3s' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>IEEE</a> website.
              </>
            }
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9hxnbbkqbcy6vqT-PV9QSQZsMC_npA1Oyxebu6sEr7A&s"
            width="400"
            height="400"
            position="left"
          />
        </div>
      </div>
      <div className="flex-container section2 title-text">
        <div className="title-table">
          <div className="title-cell title-text">
            <h1>Our Branch</h1>
            <h3>What is IEEE-AlexSB ?</h3>
            <br></br>
            <br></br>
            <img src="./images/ieee_bg_crop.png"></img>
          </div>
        </div>
<CardComponent
          title="IEEE AlexSB History"
          description={
            <>
              IEEE Alexandria Student Branch (IEEE AlexSB) was founded in the academic year 2000-2001. Since then, the Branch has been one of the most active branches established not only under Egypt's Local Section, but also in the whole of IEEE Region 8.
            </>
          }
          image="./images/history.jpg"
          width="400"
          height="400"
          position="left"
        />
        <CardComponent
          title="IEEE AlexSB Achievements"
          description={
            <>
              Over the years, IEEE AlexSB has organized numerous events and activities that have significantly contributed to the professional and technical growth of its members. Learn more about our achievements and how you can be part of this thriving community by visiting our <a href="https://www.facebook.com/IEEE.AlexSB/" target="_blank" rel="noopener noreferrer">facebook</a> Page.
            </>
          }
          image="./images/activities.png"
          width="400"
          height="400"
          position="left"
        />
      </div>
    </div>
  );
};

export default Benefits;
