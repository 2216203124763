import React from 'react';
import Activities from '../components/activities/activities';

function activities(){
    return(
        <div>
        <Activities />
        </div>
    );
}
export default activities; 